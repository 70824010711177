import { createBrowserRouter } from 'react-router-dom';
import ChatPage from '@/pages/ChatPage';
import ChatRedirectedErrorPage from '@/pages/ChatRedirectedErrorPage';
import ThemeWrapperChat from '@/layouts/ThemeWrapperChat';

const RouterWrapper = () => {
  const router = createBrowserRouter([
    {
      element: <ThemeWrapperChat />,
      children: [
        {
          index: true,
          path: '/:chat_id',
          element: <ChatPage />,
        },
        // catch errors
        {
          path: '*',
          element: <ChatRedirectedErrorPage />,
        },
      ],
    },
  ]);

  return router;
};

export default RouterWrapper;
