import { useRef, useEffect } from 'react';

interface IDocumentTitle {
  title: string;
  favicon?: string;
  prevailOnUnmount?: boolean;
}

function useChatTitle({ title, favicon, prevailOnUnmount = false }: IDocumentTitle) {
  const defaultTitle = useRef(document.title);
  const defaultFavicon = useRef(document.querySelector("link[rel~='icon']")?.getAttribute('href'));

  function updateFavicon(faviconUrl: string) {
    const link: HTMLLinkElement | null = document.querySelector('#favicon-icon') || document.createElement('link');
    link.id = 'favicon-icon';
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = faviconUrl;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  useEffect(() => {
    document.title = title;
    if (favicon) {
      updateFavicon(favicon);
    }
  }, [title, favicon]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
      if (defaultFavicon.current) {
        updateFavicon(defaultFavicon.current);
      }
    }
  }, [prevailOnUnmount]);
}

export default useChatTitle;
