import { create } from 'zustand';

type State = {
  chatSettingsData: string[]
};

type Actions = {
  setChatSettingsData: (settings: string[]) => void;
};

const useChatSettingsStore = create<State & Actions>((set) => {
  return {
    chatSettingsData: ['audio', 'video', 'text'],
    setChatSettingsData: (value) => set({ chatSettingsData: value }),
  };
});

export default useChatSettingsStore;
