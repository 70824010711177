import { useState, useCallback, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import useChatStore from '../store';
import useSendMessage from './useSendMessage';
import Webcam from 'react-webcam';

enum EVideoStatus {
  IDLE = 'idle',
  RECORDING = 'recording',
  PAUSED = 'paused',
  STOPPED = 'stopped',
}

export const useVideoRecorder = () => {
  const { addMessageAndStartCreateAsistant, currentProjectToken, setShowVoiceMainInput } = useChatStore((state) => state);
  const sendMessage = useSendMessage();

  const webcamRef = useRef<Webcam>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState<string | undefined>(undefined);
  const [facingMode, setFacingMode] = useState<'user' | 'environment'>('user');
  const [swithcingFacingModePlaceholder, setSwitchingFacingModePlaceholder] = useState(false)
  const [isVideoRecordingPressed, setIsVideoRecordingPressed] = useState(false);
  const [showVideoRecordingModal, setShowVideoRecordingModal] = useState(false);
  const [statusVideo, setStatusVideo] = useState(EVideoStatus.IDLE);
  const [isVideoSending, setIsVideoSending] = useState(false);
  const [isVideoStopping, setIsVideoStopping] = useState(false);
  const [isVideoPausing, setIsVideoPausing] = useState(false);
  const [isVideoExiting, setIsVideoExiting] = useState(false);
  const [isVideoStarting, setIsVideoStarting] = useState(false);
  const [isVideoResuming, setIsVideoResuming] = useState(false);

  // recorded video blob
  let video: Blob | null = recordedChunks?.[0];

  const handleDataAvailable = useCallback((event: BlobEvent) => {
    if (event.data.size > 0) {
      setRecordedChunks((prev) => prev.concat(event.data));
    }
  }, []);

  const startRecording = useCallback(() => {
    if (webcamRef.current && webcamRef.current.stream) {
      const audioStream = webcamRef.current.stream.getAudioTracks();
      const videoStream = webcamRef.current.stream.getVideoTracks();
      const combinedStream = new MediaStream([...audioStream, ...videoStream]);

      mediaRecorderRef.current = new MediaRecorder(combinedStream, { mimeType: 'video/mp4' });
      mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
      mediaRecorderRef.current.start();
    }
  }, [handleDataAvailable]);

  const handleStartVideoRecording = useCallback(async () => {
    setIsVideoStarting(true);
    try {
      setRecordedChunks([]);
      setVideoPreviewUrl(undefined);
      setCapturing(true);
      setStatusVideo(EVideoStatus.RECORDING);
      startRecording();
      setIsVideoRecordingPressed(true);
    } finally {
      setIsVideoStarting(false);
    }
  }, [startRecording]);

  const handleOpenVideoModal = useCallback(async () => {
    setShowVideoRecordingModal(true);
    setIsVideoRecordingPressed(true);
  }, []);

  const handleCloseVideoModal = () => {
    setShowVideoRecordingModal(false);
    setIsVideoRecordingPressed(false);
  }

  const handlePauseVideoRecording = useCallback(async () => {
    setIsVideoPausing(true);
    try {
      if (mediaRecorderRef.current?.state === 'recording') {
        mediaRecorderRef.current.pause();
        setStatusVideo(EVideoStatus.PAUSED);
      }
    } finally {
      setIsVideoPausing(false);
    }
  }, []);

  const handleResumeVideoRecording = useCallback(async () => {
    setIsVideoResuming(true)
    try {
      if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'paused') {
        mediaRecorderRef.current.resume();
        setStatusVideo(EVideoStatus.RECORDING);
      }
    } finally {
      setIsVideoResuming(false);
    }
  }, [startRecording]);

  const handleStopVideoRecording = useCallback(async () => {
    setIsVideoStopping(true);
    try {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
        setCapturing(false);
        setStatusVideo(EVideoStatus.STOPPED);
        setFacingMode('user');
      }
    } finally {
      setIsVideoStopping(false);
    }
  }, []);

  const handleExitVideoRecording = useCallback(() => {
    setIsVideoExiting(true);
    try {
      mediaRecorderRef.current = null;
      setCapturing(false);
      setStatusVideo(EVideoStatus.IDLE);
      handleCloseVideoModal();
      setFacingMode('user');
    } finally {
      setIsVideoExiting(false);
    }
  }, []);

  const handleFlipCamera = useCallback(() => {
    setSwitchingFacingModePlaceholder(true);
    setTimeout(() => {
      setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
      setSwitchingFacingModePlaceholder(false);
    }, 1500);
  }, []);

  const sendVideoMessage = useCallback(
    async (videoBlob: Blob) => {
      try {
        await sendMessage({
          file: videoBlob,
          text: '',
          onError: console.error,
          projectToken: currentProjectToken,
        });

        addMessageAndStartCreateAsistant({
          _id: Math.random().toString(),
          role: 'user',
          text: '',
          loading: false,
          data: [{ type: 'video', url: URL.createObjectURL(videoBlob), isUser: true }],
          created_at: new Date().toISOString(),
        });
      } catch {
        toast.error('Error sending video message.');
      }
    },
    [sendMessage, addMessageAndStartCreateAsistant, currentProjectToken]
  );

  const handleSubmitVideo = useCallback(async () => {
    if (!video || isVideoSending) return;
    setIsVideoSending(true);

    const blob = new Blob(recordedChunks, {
      type: "video/mp4"
    });

    try {
      await sendVideoMessage(blob);
      video = null;
      handleExitVideoRecording();
      setShowVideoRecordingModal(false);
      setIsVideoRecordingPressed(false);
      setFacingMode('user');
    } catch (err) {
      console.error(err);
    } finally {
      setIsVideoSending(false);
    }
  }, [video, sendVideoMessage]);

  useEffect(() => () => {
    if (videoPreviewUrl) {
      URL.revokeObjectURL(videoPreviewUrl);
      setVideoPreviewUrl(undefined);
    }
  }, [videoPreviewUrl]);

  const generatePreview = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, { type: 'video/mp4' });
      const previewUrl = URL.createObjectURL(blob);
      setVideoPreviewUrl(previewUrl);
    }
  }, [recordedChunks]);

  useEffect(() => {
    if (!capturing && recordedChunks.length > 0) {
      generatePreview();
    }
  }, [capturing, recordedChunks, generatePreview]);

  return {
    statusVideo,
    previewStream: webcamRef,
    isVideoRecordingActive: capturing,
    showVideoRecordingModal,
    handleStartVideoRecording,
    handleStopVideoRecording,
    handleExitVideoRecording,
    handlePauseVideoRecording,
    handleResumeVideoRecording,
    handleSubmitVideo,
    mediaBlobUrl: video,
    isVideoRecordingPressed,
    handleFlipCamera,
    facingMode,
    handleOpenVideoModal,
    videoPreviewUrl,
    capturing,
    setShowVoiceMainInput,
    handleCloseVideoModal,
    swithcingFacingModePlaceholder,
    isVideoSending,
    isVideoStopping,
    isVideoPausing,
    isVideoExiting,
    isVideoStarting,
    isVideoResuming
  };
};