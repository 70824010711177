import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

// eslint-disable-next-line import/prefer-default-export
export const useThemeQuery = (projectId: string | undefined) => useQuery({
  queryFn: () => axios.get(`${import.meta.env.VITE_API_BASE_URL}/projects/get${projectId}/theme`),
  queryKey: ['chat_themes_branding', { projectId }],
  enabled: !!projectId,
  select: (data) => data?.data,
});
