interface Translation {
  [key: string]: string;
}

// Welcome
export const translateWelcome: Translation = {
  af: 'Welkom',
  ar: 'أهلا بك',
  hy: 'Բարի գալուստ',
  az: 'Xoş gəldiniz',
  be: 'Сардэчна запрашаем',
  bs: 'Dobrodošli',
  bg: 'Добре дошли',
  ca: 'Benvingut',
  zh: '欢迎',
  hr: 'Dobrodošli',
  cs: 'Vítejte',
  da: 'Velkommen',
  nl: 'Welkom',
  en: 'Welcome',
  et: 'Tere tulemast',
  fi: 'Tervetuloa',
  fr: 'Bienvenue',
  gl: 'Benvido',
  de: 'Willkommen',
  el: 'Καλώς ήρθατε',
  he: 'ברוך הבא',
  hi: 'स्वागत है',
  hu: 'Üdvözlöm',
  is: 'Velkomin',
  it: 'Benvenuto',
  ja: 'ようこそ',
  kn: 'ಸ್ವಾಗತ',
  kk: 'Қош келдіңіз',
  ko: '환영합니다',
  mk: 'Добредојдовте',
  ms: 'Selamat datang',
  mi: 'Nau mai',
  mr: 'स्वागत आहे',
  pa: 'ਸੁਆਗਤ ਹੈ',
  fa: 'خوش آمدید',
  pl: 'Witamy',
  pt: 'Bem-vindo',
  ro: 'Bun venit',
  ru: 'Добро пожаловать',
  sr: 'Добродошли',
  sk: 'Vitajte',
  sl: 'Dobrodošli',
  es: 'Bienvenido',
  sw: 'Karibu',
  sv: 'Välkommen',
  ta: 'வரவேற்கிறோம்',
  th: 'ยินดีต้อนรับ',
  tr: 'Hoş geldiniz',
  uk: 'Ласкаво просимо',
  ur: 'خوش آمدید',
  vi: 'Chào mừng',
  cy: 'Croeso',
};

// This is not a typical, boring survey.
export const translateSurvey: Translation = {
  af: 'Dit is nie ’n tipiese opname nie.',
  ar: 'هذه ليست استبيانًا عاديًا.',
  hy: 'Սա տիպիկ հարցում չէ։',
  az: 'Bu tipik bir sorğu deyil.',
  be: 'Гэта не звычайнае апытанне.',
  bs: 'Ovo nije tipična anketa.',
  bg: 'Това не е типично проучване.',
  ca: 'Aquesta no és una enquesta típica.',
  zh: '这不是一个典型的调查。',
  hr: 'Ovo nije tipična anketa.',
  cs: 'Tohle není typický průzkum.',
  da: 'Dette er ikke en typisk undersøgelse.',
  nl: 'Dit is geen typische enquête.',
  en: 'This is not a typical survey.',
  et: 'See ei ole tüüpiline küsitlus.',
  fi: 'Tämä ei ole tyypillinen kysely.',
  fr: 'Ce n’est pas un sondage typique.',
  gl: 'Esta non é unha enquisa típica.',
  de: 'Dies ist keine typische Umfrage.',
  el: 'Αυτή δεν είναι μια τυπική έρευνα.',
  he: 'זה לא סקר טיפוסי.',
  hi: 'यह एक सामान्य सर्वेक्षण नहीं है।',
  hu: 'Ez nem egy tipikus felmérés.',
  is: 'Þetta er ekki dæmigerð könnun.',
  it: 'Questo non è un sondaggio tipico.',
  ja: 'これは典型的な調査ではありません。',
  kn: 'ಇದು ಸಾಮಾನ್ಯ ಸಮೀಕ್ಷೆ ಅಲ್ಲ.',
  kk: 'Бұл әдеттегі сауалнама емес.',
  ko: '이것은 전형적인 설문 조사가 아닙니다.',
  mk: 'Ова не е типична анкета.',
  ms: 'Ini bukan tinjauan biasa.',
  mi: 'Ehara tenei i te rangahau tauhou.',
  mr: 'हे सामान्य सर्वेक्षण नाही.',
  pa: 'ਇਹ ਇੱਕ ਸਧਾਰਣ ਸਰਵੇ ਨਹੀਂ ਹੈ।',
  fa: 'این یک نظرسنجی معمولی نیست.',
  pl: 'To nie jest typowa ankieta.',
  pt: 'Esta não é uma pesquisa típica.',
  ro: 'Acesta nu este un sondaj tipic.',
  ru: 'Это не типичный опрос.',
  sr: 'Ово није типична анкета.',
  sk: 'Toto nie je typický prieskum.',
  sl: 'To ni tipična anketa.',
  es: 'Esto no es una encuesta típica.',
  sw: 'Hii si uchunguzi wa kawaida.',
  sv: 'Detta är inte en typisk undersökning.',
  ta: 'இது ஒரு சாதாரண கருத்துக்கணிப்பு அல்ல.',
  th: 'นี่ไม่ใช่แบบสำรวจทั่วไป',
  tr: 'Bu tipik bir anket değil.',
  uk: 'Це не типове опитування.',
  ur: 'یہ ایک عام سروے نہیں ہے۔',
  vi: 'Đây không phải là một cuộc khảo sát điển hình.',
  cy: 'Nid yw hwn yn arolwg arferol.',
};

// This is an interactive AI interview, designed to hear your unique perspective.
export const translateAIInterview: Translation = {
  af: 'Dit is ’n interaktiewe KI-onderhoud, ontwerp om jou unieke perspektief te hoor.',
  ar: 'هذه مقابلة تفاعلية بالذكاء الاصطناعي، مصممة لسماع وجهة نظرك الفريدة.',
  hy: 'Սա ինտերակտիվ արհեստական բանականության հարցազրույց է՝ նախատեսված ձեր յուրահատուկ տեսակետը լսելու համար։',
  az: 'Bu, sizin unikal baxışınızı eşitmək üçün nəzərdə tutulmuş interaktiv AI müsahibəsidir.',
  be: 'Гэта інтэрактыўнае інтэрв’ю з ІІ, створанае для таго, каб пачуць ваш унікальны погляд.',
  bs: 'Ovo je interaktivni AI intervju, osmišljen da čuje vaše jedinstvene perspektive.',
  bg: 'Това е интерактивно интервю с изкуствен интелект, създадено да чуе вашата уникална гледна точка.',
  ca: 'Aquesta és una entrevista interactiva amb IA, dissenyada per escoltar la teva perspectiva única.',
  zh: '这是一个互动式的AI面试，旨在倾听您的独特观点。',
  hr: 'Ovo je interaktivni AI intervju, dizajniran da čuje vaše jedinstveno gledište.',
  cs: 'Toto je interaktivní AI rozhovor, navržený tak, aby slyšel váš jedinečný pohled.',
  da: 'Dette er et interaktivt AI-interview, designet til at høre dit unikke perspektiv.',
  nl: 'Dit is een interactief AI-interview, ontworpen om jouw unieke perspectief te horen.',
  en: 'This is an interactive AI interview, designed to hear your unique perspective.',
  et: 'See on interaktiivne tehisintellekti intervjuu, loodud kuulama teie ainulaadset vaatenurka.',
  fi: 'Tämä on interaktiivinen tekoälyhaastattelu, joka on suunniteltu kuulemaan ainutlaatuinen näkökulmasi.',
  fr: 'Ceci est une interview interactive par IA, conçue pour entendre votre perspective unique.',
  gl: 'Esta é unha entrevista interactiva de IA deseñada para escoitar a túa perspectiva única.',
  de: 'Dies ist ein interaktives KI-Interview, das entwickelt wurde, um Ihre einzigartige Perspektive zu hören.',
  el: 'Αυτή είναι μια διαδραστική συνέντευξη με τεχνητή νοημοσύνη, σχεδιασμένη για να ακούσει τη μοναδική σας προοπτική.',
  he: 'זו ראיון אינטראקטיבי עם בינה מלאכותית, שנועד לשמוע את נקודת המבט הייחודית שלך.',
  hi: 'यह एक इंटरएक्टिव एआई साक्षात्कार है, जिसे आपके अनोखे दृष्टिकोण को सुनने के लिए डिज़ाइन किया गया है।',
  hu: 'Ez egy interaktív mesterséges intelligencia interjú, amelyet az Ön egyedi nézőpontjának meghallgatására terveztek.',
  is: 'Þetta er gagnvirt gervigreindarviðtal, hannað til að heyra einstaka sjónarhorn þitt.',
  it: 'Questa è un’intervista interattiva con IA, progettata per ascoltare la tua prospettiva unica.',
  ja: 'これはインタラクティブなAIインタビューで、あなたのユニークな視点を聞くために設計されています。',
  kn: 'ಇದು ಒಂದು ಸಂವಾದಾತ್ಮಕ AI ಸಂದರ್ಶನ, ನಿಮ್ಮ ವಿಶಿಷ್ಟ ದೃಷ್ಟಿಕೋಣವನ್ನು ಕೇಳಲು ವಿನ್ಯಾಸಗೊಳಿಸಲಾಗಿದೆ.',
  kk: 'Бұл сіздің ерекше көзқарасыңызды тыңдау үшін жасалған интерактивті AI сұхбаты.',
  ko: '이것은 귀하의 독특한 관점을 듣기 위해 설계된 대화형 AI 인터뷰입니다.',
  mk: 'Ова е интерактивно интервју со вештачка интелигенција, дизајнирано да го чуе вашето уникатно гледиште.',
  ms: 'Ini adalah temu bual AI interaktif, direka untuk mendengar perspektif unik anda.',
  mi: 'He uiuitanga AI tauwhitiwhiti tēnei, i hangaia hei whakarongo ki tō tirohanga ahurei.',
  mr: 'हे एक संवादात्मक एआय मुलाखत आहे, आपली अद्वितीय दृष्टीकोन ऐकण्यासाठी डिझाइन केलेले आहे.',
  pa: 'ਇਹ ਇੱਕ ਇੰਟਰਐਕਟਿਵ ਏਆਈ ਇੰਟਰਵਿਊ ਹੈ, ਜੋ ਤੁਹਾਡੇ ਵਿਲੱਖਣ ਨਜ਼ਰੀਏ ਨੂੰ ਸੁਣਨ ਲਈ ਤਿਆਰ ਕੀਤਾ ਗਿਆ ਹੈ।',
  fa: 'این یک مصاحبه تعاملی با هوش مصنوعی است که برای شنیدن دیدگاه منحصربه‌فرد شما طراحی شده است.',
  pl: 'To jest interaktywny wywiad AI zaprojektowany, aby usłyszeć twoją unikalną perspektywę.',
  pt: 'Esta é uma entrevista interativa de IA, projetada para ouvir sua perspectiva única.',
  ro: 'Acesta este un interviu interactiv AI, conceput pentru a auzi perspectiva dumneavoastră unică.',
  ru: 'Это интерактивное интервью с ИИ, созданное для того, чтобы услышать вашу уникальную точку зрения.',
  sr: 'Ово је интерактивни интервју са вештачком интелигенцијом, дизајниран да чује вашу јединствену перспективу.',
  sk: 'Toto je interaktívny AI rozhovor, navrhnutý tak, aby počul váš jedinečný pohľad.',
  sl: 'To je interaktivni intervju z umetno inteligenco, zasnovan za slišanje vašega edinstvenega pogleda.',
  es: 'Esta es una entrevista interactiva con IA, diseñada para escuchar tu perspectiva única.',
  sw: 'Hii ni mahojiano ya AI yanayoshirikisha, yaliyoundwa kusikiliza mtazamo wako wa kipekee.',
  sv: 'Detta är en interaktiv AI-intervju, utformad för att höra ditt unika perspektiv.',
  ta: 'இது உங்கள் தனித்துவமான பார்வையை கேட்க வடிவமைக்கப்பட்ட உரையாடல் எஐ நேர்காணல்.',
  th: 'นี่คือการสัมภาษณ์ AI แบบโต้ตอบที่ออกแบบมาเพื่อรับฟังมุมมองเฉพาะตัวของคุณ',
  tr: 'Bu, benzersiz bakış açınızı duymak için tasarlanmış etkileşimli bir yapay zeka röportajıdır.',
  uk: 'Це інтерактивне інтерв\'ю з ШІ, створене, щоб почути вашу унікальну точку зору.',
  ur: 'یہ ایک انٹرایکٹو اے آئی انٹرویو ہے، جو آپ کے منفرد نقطہ نظر کو سننے کے لیے تیار کیا گیا ہے۔',
  vi: 'Đây là một cuộc phỏng vấn AI tương tác, được thiết kế để lắng nghe quan điểm độc đáo của bạn.',
  cy: 'Mae hwn yn gyfweliad AI rhyngweithiol, wedi’i gynllunio i glywed eich safbwynt unigryw.',
};

// Pick your language, and then we’ll begin.
export const translatePickLanguage: Translation = {
  af: 'Kies jou taal, en dan begin ons.',
  ar: 'اختر لغتك، ثم سنبدأ.',
  hy: 'Ընտրեք ձեր լեզուն, և ապա մենք կսկսենք։',
  az: 'Dilinizi seçin, sonra başlayırıq.',
  be: 'Абярыце сваю мову, і тады мы пачнем.',
  bs: 'Odaberite svoj jezik, a zatim ćemo početi.',
  bg: 'Изберете своя език и след това ще започнем.',
  ca: 'Tria el teu idioma i després començarem.',
  zh: '选择您的语言，然后我们就开始。',
  hr: 'Odaberite svoj jezik, a zatim ćemo započeti.',
  cs: 'Vyberte si svůj jazyk, a pak začneme.',
  da: 'Vælg dit sprog, og så begynder vi.',
  nl: 'Kies je taal en dan beginnen we.',
  en: 'Pick your language, and then we’ll begin.',
  et: 'Valige oma keel ja siis alustame.',
  fi: 'Valitse kielesi, niin aloitamme.',
  fr: 'Choisissez votre langue, puis nous commencerons.',
  gl: 'Escolle o teu idioma e logo comezaremos.',
  de: 'Wählen Sie Ihre Sprache, und dann beginnen wir.',
  el: 'Επιλέξτε τη γλώσσα σας και στη συνέχεια θα ξεκινήσουμε.',
  he: 'בחר את השפה שלך, ואז נתחיל.',
  hi: 'अपनी भाषा चुनें, और फिर हम शुरू करेंगे।',
  hu: 'Válassza ki a nyelvét, és akkor kezdünk.',
  is: 'Veldu tungumálið þitt, og þá byrjum við.',
  it: 'Scegli la tua lingua e poi inizieremo.',
  ja: '言語を選んでから始めましょう。',
  kn: 'ನಿಮ್ಮ ಭಾಷೆಯನ್ನು ಆರಿಸಿ, ನಂತರ ನಾವು ಪ್ರಾರಂಭಿಸುತ್ತೇವೆ.',
  kk: 'Тіліңізді таңдаңыз, содан кейін біз бастаймыз.',
  ko: '언어를 선택하면 시작합니다.',
  mk: 'Изберете го вашиот јазик, а потоа ќе започнеме.',
  ms: 'Pilih bahasa anda, dan kemudian kami akan mula.',
  mi: 'Tīpakohia tō reo, kātahi ka tīmata tātou.',
  mr: 'तुमची भाषा निवडा, आणि मग आपण सुरुवात करू.',
  pa: 'ਆਪਣੀ ਭਾਸ਼ਾ ਚੁਣੋ, ਅਤੇ ਫਿਰ ਅਸੀਂ ਸ਼ੁਰੂ ਕਰਾਂਗੇ।',
  fa: 'زبان خود را انتخاب کنید، سپس شروع می‌کنیم.',
  pl: 'Wybierz swój język, a następnie zaczniemy.',
  pt: 'Escolha o seu idioma e depois começaremos.',
  ro: 'Alegeți limba dvs., apoi vom începe.',
  ru: 'Выберите ваш язык, а затем мы начнем.',
  sr: 'Изаберите свој језик, а затим ћемо почети.',
  sk: 'Vyberte si svoj jazyk a potom začneme.',
  sl: 'Izberite svoj jezik, nato bomo začeli.',
  es: 'Elige tu idioma y luego comenzaremos.',
  sw: 'Chagua lugha yako, kisha tutaanza.',
  sv: 'Välj ditt språk, och sedan börjar vi.',
  ta: 'உங்கள் மொழியைத் தேர்ந்தெடுக்கவும், பிறகு நாம் தொடங்குவோம்.',
  th: 'เลือกภาษาของคุณ จากนั้นเราจะเริ่มต้น',
  tr: 'Dilinizi seçin ve sonra başlayalım.',
  uk: 'Виберіть свою мову, а тоді ми почнемо.',
  ur: 'اپنی زبان منتخب کریں، اور پھر ہم شروع کریں گے۔',
  vi: 'Chọn ngôn ngữ của bạn, sau đó chúng tôi sẽ bắt đầu.',
  cy: 'Dewiswch eich iaith, ac yna byddwn yn dechrau.',
};

// Let's go
export const translateLetsGo: Translation = {
  af: 'Kom ons begin',
  ar: 'لنبدأ',
  hy: 'Սկսենք',
  az: 'Başlayaq',
  be: 'Пачнем',
  bs: 'Počnimo',
  bg: 'Нека започнеме',
  ca: 'Comencem',
  zh: '让我们开始吧',
  hr: 'Krenimo',
  cs: 'Začněme',
  da: 'Lad os begynde',
  nl: 'Laten we beginnen',
  en: "Let's go",
  et: 'Alustame',
  fi: 'Aloitetaan',
  fr: 'Commençons',
  gl: 'Empecemos',
  de: 'Lass uns anfangen',
  el: 'Ας ξεκινήσουμε',
  he: 'בוא נתחיל',
  hi: 'आइए शुरू करें',
  hu: 'Kezdjük el',
  is: 'Byrjum við',
  it: 'Cominciamo',
  ja: '始めましょう',
  kn: 'ಪ್ರಾರಂಭಿಸೋಣ',
  kk: 'Бастау',
  ko: '시작해봅시다',
  mk: 'Да започнеме',
  ms: 'Mari mula',
  mi: 'Tīmataratanga',
  mr: 'आपल्या आरंभा',
  pa: 'ਸ਼ੁਰੂ ਕਰੀਏ',
  fa: 'بیا شروع کنیم',
  pl: 'Zacznijmy',
  pt: 'Vamos começar',
  ro: 'Să începem',
  ru: 'Давайте начнем',
  sr: 'Да почнемо',
  sk: 'Začnime',
  sl: 'Začnimo',
  es: 'Comencemos',
  sw: 'Tuangalie',
  sv: 'Låt oss börja',
  ta: 'தொடங்கவேண்டும்',
  th: 'เริ่มต้นเถอะ',
  tr: 'Başlayalım',
  uk: 'Давайте почнемо',
  ur: 'شروع کرتے ہیں',
  vi: 'Bắt đầu thôi',
  cy: 'Gwnewch yn dechrau',
};

// I accept {interviewTerms} and {privacyPolicy}
export const translations = {
  af: {
    firstParagraph: 'Ek aanvaar {interviewTerms} en {privacyPolicy}',
    interviewTerms: 'Onderhoudsvoorwaardes',
    privacyPolicy: 'Privaatheidsbeleid',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  ar: {
    firstParagraph: 'أوافق على {interviewTerms} و {privacyPolicy}',
    interviewTerms: 'شروط المقابلة',
    privacyPolicy: 'سياسة الخصوصية',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  hy: {
    firstParagraph: 'Ես համաձայն եմ {interviewTerms} և {privacyPolicy}',
    interviewTerms: 'Հարցազրույցի պայմաններ',
    privacyPolicy: 'Գաղտնիության քաղաքականություն',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  az: {
    firstParagraph: 'Mən {interviewTerms} və {privacyPolicy} ilə razıyam',
    interviewTerms: 'Müsahibə şərtləri',
    privacyPolicy: 'Məxfilik Siyasəti',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  be: {
    firstParagraph: 'Я прымаю {interviewTerms} і {privacyPolicy}',
    interviewTerms: "Умовы інтэрв'ю",
    privacyPolicy: 'Палітыка прыватнасці',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  bs: {
    firstParagraph: 'Prihvatam {interviewTerms} i {privacyPolicy}',
    interviewTerms: 'Uvjeti intervjua',
    privacyPolicy: 'Politika privatnosti',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  bg: {
    firstParagraph: 'Признавам {interviewTerms} и {privacyPolicy}',
    interviewTerms: 'Условия за интервю',
    privacyPolicy: 'Политика за поверителност',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  ca: {
    firstParagraph: 'Accepto {interviewTerms} i {privacyPolicy}',
    interviewTerms: "Termes de l'entrevista",
    privacyPolicy: 'Política de privacitat',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  zh: {
    firstParagraph: '我接受{interviewTerms}和{privacyPolicy}',
    interviewTerms: '面试条款',
    privacyPolicy: '隐私政策',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  hr: {
    firstParagraph: 'Prihvaćam {interviewTerms} i {privacyPolicy}',
    interviewTerms: 'Uslovi intervjua',
    privacyPolicy: 'Politika privatnosti',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  cs: {
    firstParagraph: 'Souhlasím s {interviewTerms} a {privacyPolicy}',
    interviewTerms: 'Podmínky rozhovoru',
    privacyPolicy: 'Zásady ochrany osobních údajů',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  da: {
    firstParagraph: 'Jeg accepterer {interviewTerms} og {privacyPolicy}',
    interviewTerms: 'Interviewvilkår',
    privacyPolicy: 'Fortrolighedspolitik',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  nl: {
    firstParagraph: 'Ik accepteer {interviewTerms} en {privacyPolicy}',
    interviewTerms: 'Interviewvoorwaarden',
    privacyPolicy: 'Privacybeleid',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  en: {
    firstParagraph: 'I accept {interviewTerms} and {privacyPolicy}',
    interviewTerms: 'Interview Terms',
    privacyPolicy: 'Privacy Policy',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  et: {
    firstParagraph: 'Ma nõustun {interviewTerms} ja {privacyPolicy}',
    interviewTerms: 'Intervjuu tingimused',
    privacyPolicy: 'Privaatsuspoliitika',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  fi: {
    firstParagraph: 'Hyväksyn {interviewTerms} ja {privacyPolicy}',
    interviewTerms: 'Haastatteluehdot',
    privacyPolicy: 'Tietosuojakäytäntö',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  fr: {
    firstParagraph: 'J\'accepte {interviewTerms} et {privacyPolicy}',
    interviewTerms: "Conditions d'entretien",
    privacyPolicy: 'Politique de confidentialité',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  gl: {
    firstParagraph: 'Acepto {interviewTerms} e {privacyPolicy}',
    interviewTerms: 'Termos da entrevista',
    privacyPolicy: 'Política de privacidade',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  de: {
    firstParagraph: 'Ich akzeptiere {interviewTerms} und {privacyPolicy}',
    interviewTerms: 'Interviewbedingungen',
    privacyPolicy: 'Datenschutzrichtlinie',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  el: {
    firstParagraph: 'Αποδέχομαι {interviewTerms} και {privacyPolicy}',
    interviewTerms: 'Όροι συνέντευξης',
    privacyPolicy: 'Πολιτική απορρήτου',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  he: {
    firstParagraph: 'אני מקבל {interviewTerms} ו {privacyPolicy}',
    interviewTerms: 'תנאי הראיון',
    privacyPolicy: 'מדיניות פרטיות',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  hi: {
    firstParagraph: 'मैं {interviewTerms} और {privacyPolicy} को स्वीकार करता हूँ',
    interviewTerms: 'साक्षात्कार की शर्तें',
    privacyPolicy: 'गोपनीयता नीति',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  hu: {
    firstParagraph: 'Elfogadom a(z) {interviewTerms} és {privacyPolicy}',
    interviewTerms: 'Interjú feltételei',
    privacyPolicy: 'Adatvédelmi irányelvek',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  is: {
    firstParagraph: 'Ég samþykki {interviewTerms} og {privacyPolicy}',
    interviewTerms: 'Viðtalskjör',
    privacyPolicy: 'Persónuverndarstefna',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  it: {
    firstParagraph: 'Accetto {interviewTerms} e {privacyPolicy}',
    interviewTerms: 'Termini del colloquio',
    privacyPolicy: 'Politica sulla riservatezza',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  ja: {
    firstParagraph: '私は{interviewTerms}と{privacyPolicy}に同意します',
    interviewTerms: 'インタビューポリシー',
    privacyPolicy: 'プライバシーポリシー',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  kn: {
    firstParagraph: 'ನಾನು {interviewTerms} ಮತ್ತು {privacyPolicy} ಒಪ್ಪಿಗೆಯಾದೇನೆ',
    interviewTerms: 'ಮೂಲಕ ನಿಯಮಗಳು',
    privacyPolicy: 'ಗೌಪ್ಯತಾ ನೀತಿ',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  kk: {
    firstParagraph: 'Мен {interviewTerms} және {privacyPolicy} келісемін',
    interviewTerms: 'Сұхбат шарттары',
    privacyPolicy: 'Құпиялылық саясаты',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  ko: {
    firstParagraph: '저는 {interviewTerms}와 {privacyPolicy}에 동의합니다',
    interviewTerms: '인터뷰 약관',
    privacyPolicy: '개인정보 처리방침',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  mk: {
    firstParagraph: 'Прифатам {interviewTerms} и {privacyPolicy}',
    interviewTerms: 'Услови за интервју',
    privacyPolicy: 'Политика за приватност',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  ms: {
    firstParagraph: 'Saya terima {interviewTerms} dan {privacyPolicy}',
    interviewTerms: 'Terma Wawancara',
    privacyPolicy: 'Dasar Privasi',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  mi: {
    firstParagraph: 'Kei te whakaae au ki {interviewTerms} me {privacyPolicy}',
    interviewTerms: 'Ngā tikanga uiui',
    privacyPolicy: 'Ture Tūmataiti',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  no: {
    firstParagraph: 'Jeg aksepterer {interviewTerms} og {privacyPolicy}',
    interviewTerms: 'Intervjuforhold',
    privacyPolicy: 'Personvernpolitikk',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  pl: {
    firstParagraph: 'Akceptuję {interviewTerms} i {privacyPolicy}',
    interviewTerms: 'Warunki rozmowy kwalifikacyjnej',
    privacyPolicy: 'Polityka prywatności',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  pt: {
    firstParagraph: 'Aceito {interviewTerms} e {privacyPolicy}',
    interviewTerms: 'Termos da entrevista',
    privacyPolicy: 'Política de privacidade',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  ro: {
    firstParagraph: 'Accept {interviewTerms} și {privacyPolicy}',
    interviewTerms: 'Termeni de interviu',
    privacyPolicy: 'Politica de confidențialitate',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  ru: {
    firstParagraph: 'Я принимаю {interviewTerms} и {privacyPolicy}',
    interviewTerms: 'Условия собеседования',
    privacyPolicy: 'Политика конфиденциальности',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  sr: {
    firstParagraph: 'Prihvatam {interviewTerms} i {privacyPolicy}',
    interviewTerms: 'Uslovi intervjua',
    privacyPolicy: 'Politika privatnosti',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  si: {
    firstParagraph: 'මම {interviewTerms} සහ {privacyPolicy} අනුමත කරමි',
    interviewTerms: 'ස්වාධීන කොන්දේසි',
    privacyPolicy: 'පෞද්ගලිකත්ව පෝලිසිය',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  sk: {
    firstParagraph: 'Súhlasím s {interviewTerms} a {privacyPolicy}',
    interviewTerms: 'Podmienky pohovoru',
    privacyPolicy: 'Zásady ochrany osobných údajov',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  sl: {
    firstParagraph: 'Sprejem {interviewTerms} in {privacyPolicy}',
    interviewTerms: 'Pogoji za razgovor',
    privacyPolicy: 'Politika zasebnosti',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  es: {
    firstParagraph: 'Acepto {interviewTerms} y {privacyPolicy}',
    interviewTerms: 'Términos de entrevista',
    privacyPolicy: 'Política de privacidad',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  sv: {
    firstParagraph: 'Jag accepterar {interviewTerms} och {privacyPolicy}',
    interviewTerms: 'Intervjuförhållanden',
    privacyPolicy: 'Sekretesspolicy',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  tr: {
    firstParagraph: 'Ben {interviewTerms} ve {privacyPolicy} kabul ediyorum',
    interviewTerms: 'Mülakat şartları',
    privacyPolicy: 'Gizlilik Politikası',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  uk: {
    firstParagraph: 'Я приймаю {interviewTerms} та {privacyPolicy}',
    interviewTerms: 'Умови співбесіди',
    privacyPolicy: 'Політика конфіденційності',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  vi: {
    firstParagraph: 'Tôi chấp nhận {interviewTerms} và {privacyPolicy}',
    interviewTerms: 'Điều kiện phỏng vấn',
    privacyPolicy: 'Chính sách bảo mật',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  cy: {
    firstParagraph: 'Derbyniaf {interviewTerms} a {privacyPolicy}',
    interviewTerms: 'Telerau Cyfweliad',
    privacyPolicy: 'Polisi Preifatrwydd',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  xh: {
    firstParagraph: 'Ndivuma {interviewTerms} kunye {privacyPolicy}',
    interviewTerms: 'Imigaqo yeinterview',
    privacyPolicy: 'Inqubomgomo yobumfihlo',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  zu: {
    firstParagraph: 'Ngivuma {interviewTerms} kanye {privacyPolicy}',
    interviewTerms: 'Imigomo ye-Interview',
    privacyPolicy: 'Inqubomgomo yobumfihlo',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
};
