const languagesListNormalLabels = {
  language: [
    { id: 1, label: 'Afrikaans', value: 'af' },
    { id: 2, label: 'Arabic', value: 'ar' },
    { id: 3, label: 'Armenian', value: 'hy' },
    { id: 4, label: 'Azerbaijani', value: 'az' },
    { id: 5, label: 'Belarusian', value: 'be' },
    { id: 6, label: 'Bosnian', value: 'bs' },
    { id: 7, label: 'Bulgarian', value: 'bg' },
    { id: 8, label: 'Catalan', value: 'ca' },
    { id: 9, label: 'Chinese', value: 'zh' },
    { id: 10, label: 'Croatian', value: 'hr' },
    { id: 11, label: 'Czech', value: 'cs' },
    { id: 12, label: 'Danish', value: 'da' },
    { id: 13, label: 'Dutch', value: 'nl' },
    { id: 14, label: 'English', value: 'en' },
    { id: 15, label: 'Estonian', value: 'et' },
    { id: 16, label: 'Finnish', value: 'fi' },
    { id: 17, label: 'French', value: 'fr' },
    { id: 18, label: 'Galician', value: 'gl' },
    { id: 19, label: 'German', value: 'de' },
    { id: 20, label: 'Greek', value: 'el' },
    { id: 21, label: 'Hebrew', value: 'he' },
    { id: 22, label: 'Hindi', value: 'hi' },
    { id: 23, label: 'Hungarian', value: 'hu' },
    { id: 24, label: 'Icelandic', value: 'is' },
    { id: 25, label: 'Indonesian', value: 'id' },
    { id: 26, label: 'Italian', value: 'it' },
    { id: 27, label: 'Japanese', value: 'ja' },
    { id: 28, label: 'Kannada', value: 'kn' },
    { id: 29, label: 'Kazakh', value: 'kk' },
    { id: 30, label: 'Korean', value: 'ko' },
    { id: 31, label: 'Latvian', value: 'lv' },
    { id: 32, label: 'Lithuanian', value: 'lt' },
    { id: 33, label: 'Macedonian', value: 'mk' },
    { id: 34, label: 'Malay', value: 'ms' },
    { id: 35, label: 'Maori', value: 'mi' },
    { id: 36, label: 'Marathi', value: 'mr' },
    { id: 37, label: 'Nepali', value: 'ne' },
    { id: 38, label: 'Norwegian', value: 'no' },
    { id: 39, label: 'Panjabi', value: 'pa' },
    { id: 40, label: 'Persian', value: 'fa' },
    { id: 41, label: 'Polish', value: 'pl' },
    { id: 42, label: 'Portuguese', value: 'pt' },
    { id: 43, label: 'Romanian', value: 'ro' },
    { id: 44, label: 'Russian', value: 'ru' },
    { id: 45, label: 'Serbian', value: 'sr' },
    { id: 46, label: 'Slovak', value: 'sk' },
    { id: 47, label: 'Slovene', value: 'sl' },
    { id: 48, label: 'Spanish', value: 'es' },
    { id: 49, label: 'Swahili', value: 'sw' },
    { id: 50, label: 'Swedish', value: 'sv' },
    { id: 51, label: 'Tagalog', value: 'tl' },
    { id: 52, label: 'Tamil', value: 'ta' },
    { id: 53, label: 'Thai', value: 'th' },
    { id: 54, label: 'Turkish', value: 'tr' },
    { id: 55, label: 'Ukrainian', value: 'uk' },
    { id: 56, label: 'Urdu', value: 'ur' },
    { id: 57, label: 'Vietnamese', value: 'vi' },
    { id: 58, label: 'Welsh', value: 'cy' },
  ],
};

export default languagesListNormalLabels;
