import { useEffect, useState } from "react";
import { IconLoader2, IconPlayerPauseFilled, IconPlayerPlayFilled, IconPlayerStopFilled, IconSend, IconX } from "@tabler/icons-react";
import getTextColor from "../../../hooks/getTextColorContrast";
import { cn } from "../../../lib/utils";
import VideoCount from "./VideoCount";
import Webcam from 'react-webcam';
import { IconRepeat } from "@tabler/icons-react";
import { toast } from "react-toastify";

enum EVideoStatus {
	IDLE = 'idle',
	RECORDING = 'recording',
	PAUSED = 'paused',
	STOPPED = 'stopped',
}

const DEFAULT_PRIMARY_COLOR = '#096846'

interface IVideoProps {
	stream: React.RefObject<Webcam>;
	themeData: ICreateThemeData;
	handleSubmitVideo: () => void;
	isTestChat: boolean;
	facingMode: "user" | "environment";
	previewStream: React.RefObject<Webcam>;
	videoPreviewUrl: string | undefined
	handleStartVideoRecording: () => void;
	handleFlipCamera: () => void;
	statusVideo: string;
	handleStopVideoRecording: () => void;
	handlePauseVideoRecording: () => void;
	handleResumeVideoRecording: () => void;
	handleExitVideoRecording: () => void;
	capturing: boolean;
	handleCloseVideoModal: () => void;
	setShowVoiceMainInput: (value: boolean) => void;
	swithcingFacingModePlaceholder: boolean;
	isVideoSending: boolean;
	isVideoStopping: boolean;
	isVideoPausing: boolean;
	isVideoExiting: boolean;
	isVideoStarting: boolean;
	isVideoResuming: boolean;
}

function VideoPreview({
	themeData,
	isTestChat,
	facingMode,
	previewStream,
	videoPreviewUrl,
	handleStartVideoRecording,
	handleFlipCamera,
	statusVideo,
	handleStopVideoRecording,
	handlePauseVideoRecording,
	handleResumeVideoRecording,
	handleExitVideoRecording,
	capturing,
	handleSubmitVideo,
	handleCloseVideoModal,
	setShowVoiceMainInput,
	swithcingFacingModePlaceholder,
	isVideoSending,
	isVideoStopping,
	isVideoPausing,
	isVideoExiting,
	isVideoStarting,
	isVideoResuming
}: IVideoProps) {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const userAgent = navigator.userAgent;
		if (/Mobi|Android|iPhone/i.test(userAgent)) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	}, []);

	return (
		<div className={cn(
			isTestChat ? "absolute max-w-[350px] z-100 w-full h-[350px] mx-2 mb-2 -translate-x-1/2 bottom-28 left-1/2" :
				"absolute max-w-[500px] z-100 min500:max-w-full min500:h-[100dvh] min500:top-0 min500:mx-0 w-full min500:mb-0 min500:rounded-none min500:bottom-[calc(0px-77px)] h-[500px] mx-2 mb-2 -translate-x-1/2 bottom-8 left-1/2")}>
			<div
				style={{ backgroundColor: themeData?.["chat-primary"] || '#FF6F98' }}
				className="relative flex flex-col justify-between w-full h-full gap-4 p-4 min500:rounded-none z-100 rounded-xl">

				{
					statusVideo !== EVideoStatus.STOPPED && swithcingFacingModePlaceholder &&
					<div className="object-cover flex items-center justify-center w-full h-full max-h-[400px] min500:mt-1 rounded-xl min500:!max-h-[calc(100dvh-100px)]">
						<IconLoader2
							style={{ color: getTextColor(themeData?.primary || '#FFFFFF') }}
							className="animate-spin" size={36} />
					</div>
				}

				{
					statusVideo !== EVideoStatus.STOPPED && !swithcingFacingModePlaceholder &&
					<Webcam
						ref={previewStream}
						mirrored={facingMode === 'user'}
						playsInline
						videoConstraints={{
							facingMode,
						}}
						onUserMediaError={() => {
							toast.error('Please allow camera and microphone access to record a video.');
							handleCloseVideoModal();
							setShowVoiceMainInput(true);
						}}
						audioConstraints={{
							sampleSize: 16,
							echoCancellation: true,
							noiseSuppression: true,
						}}
						audio
						muted
						className="object-cover w-full h-full max-h-[400px] min500:mt-1 rounded-xl min500:!max-h-[calc(100dvh-100px)]"
					/>
				}

				{
					statusVideo == EVideoStatus.STOPPED &&
					<div className="relative w-full h-full max-h-[400px] min500:!max-h-[calc(100dvh-100px)]">
						<p className="absolute px-2 py-1 text-sm font-semibold text-black -translate-x-1/2 bg-white rounded-full top-4 left-1/2">
							Preview
						</p>

						<video
							src={videoPreviewUrl}
							controls
							playsInline
							className="object-cover w-full h-full max-h-[400px] min500:mt-1 rounded-xl min500:!max-h-[calc(100dvh-100px)]"
						/>
					</div>
				}

				<button
					onClick={handleExitVideoRecording}
					type="button" className="absolute p-1 bg-white border-2 border-black rounded-full top-2 right-2">
					<IconX size={20} className="text-black" />
				</button>


				{
					statusVideo === EVideoStatus.PAUSED &&
					<span className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 ">
						<IconPlayerPauseFilled className="text-white/30 mb-14" size={100} />
					</span>
				}

				<div className="flex items-center w-full gap-3">
					{
						statusVideo !== EVideoStatus.STOPPED
						&& <div className="flex items-center w-full gap-2">
							{
								statusVideo === EVideoStatus.PAUSED &&
								<button
									onClick={handleResumeVideoRecording}
									style={{
										backgroundColor: themeData?.primary || DEFAULT_PRIMARY_COLOR,
										color: getTextColor(themeData?.primary || DEFAULT_PRIMARY_COLOR)
									}}
									disabled={isVideoResuming}
									type="button"
									className="flex items-center justify-center w-full gap-2 px-5 py-2 text-white rounded-full disabled:opacity-70">
									{isVideoResuming ? <IconLoader2 size={22} className="animate-spin" /> : <IconPlayerPlayFilled className="mt-0.5" size={22} />}
									{isVideoResuming ? "Resuming..." : "Continue"}
								</button>
							}

							{
								capturing && statusVideo !== EVideoStatus.PAUSED &&
								<button
									onClick={handlePauseVideoRecording}
									style={{
										backgroundColor: themeData?.primary || DEFAULT_PRIMARY_COLOR,
										color: getTextColor(themeData?.primary || DEFAULT_PRIMARY_COLOR)
									}}
									type="button"
									disabled={isVideoPausing}
									className="flex items-center justify-center w-full gap-2 px-5 py-2 text-white rounded-full disabled:opacity-70">
									{isVideoPausing ? <IconLoader2 size={22} className="animate-spin" /> : <IconPlayerPauseFilled className="mt-0.5" size={20} />}
									{isVideoPausing ? "Pausing..." : "Pause"}
								</button>
							}

							{
								(capturing || statusVideo === EVideoStatus.PAUSED) &&
								<button
									onClick={handleStopVideoRecording}
									style={{
										backgroundColor: themeData?.primary || DEFAULT_PRIMARY_COLOR,
										color: getTextColor(themeData?.primary || DEFAULT_PRIMARY_COLOR)
									}}
									disabled={isVideoStopping}
									type="button"
									className="flex items-center justify-center w-full gap-2 px-5 py-2 text-white rounded-full disabled:opacity-70">
									{isVideoStopping ? <IconLoader2 size={22} className="animate-spin" /> : <IconPlayerStopFilled className="mt-0.5" size={20} />}
									{isVideoStopping ? "Stopping..." : "Stop"}
								</button>
							}

							{/* flip cammera */}
							{
								isMobile && statusVideo === EVideoStatus.IDLE &&
								<button
									onClick={handleFlipCamera}
									style={{
										backgroundColor: themeData?.primary || DEFAULT_PRIMARY_COLOR,
										color: getTextColor(themeData?.primary || DEFAULT_PRIMARY_COLOR)
									}}
									type="button"
									className="flex whitespace-nowrap items-center w-full justify-center gap-2 px-2.5 py-2 text-white rounded-full">
									<IconRepeat size={22} className="mt-0.5" />
									Switch camera
								</button>
							}

							{/* start recording */}
							{
								statusVideo === EVideoStatus.IDLE &&
								<button
									onClick={handleStartVideoRecording}
									style={{
										backgroundColor: themeData?.primary || DEFAULT_PRIMARY_COLOR,
										color: getTextColor(themeData?.primary || DEFAULT_PRIMARY_COLOR)
									}}
									disabled={isVideoStarting}
									type="button"
									className="flex items-center justify-center w-full gap-2 px-5 py-2 text-white rounded-full disabled:opacity-70">
									{isVideoStarting ? <IconLoader2 size={22} className="animate-spin" /> : <IconPlayerPlayFilled className="mt-0.5" size={20} />}
									{isVideoStarting ? "Starting..." : "Start"}
								</button>
							}

							{
								(capturing || statusVideo === EVideoStatus.PAUSED) &&
								<VideoCount
									handleStopVideoRecording={handleStopVideoRecording}
									isPaused={statusVideo === EVideoStatus.PAUSED}
									isStarted={statusVideo === EVideoStatus.RECORDING}
								/>
							}
						</div>
					}

					{
						statusVideo === EVideoStatus.STOPPED &&
						<button
							onClick={handleExitVideoRecording}
							style={{
								backgroundColor: themeData?.primary || DEFAULT_PRIMARY_COLOR,
								color: getTextColor(themeData?.primary || DEFAULT_PRIMARY_COLOR)
							}}
							disabled={isVideoExiting}
							type="button"
							className="flex items-center justify-center w-full gap-2 px-5 py-2 text-white rounded-full disabled:opacity-70">
							{isVideoExiting ? <IconLoader2 size={20} className="animate-spin" /> : <IconX size={22} className="mt-0.5" />}
							{isVideoExiting ? "Canceling..." : "Cancel"}
						</button>
					}

					{
						statusVideo === EVideoStatus.STOPPED &&
						<button
							onClick={handleSubmitVideo}
							style={{
								backgroundColor: themeData?.primary || DEFAULT_PRIMARY_COLOR,
								color: getTextColor(themeData?.primary || DEFAULT_PRIMARY_COLOR)
							}}
							disabled={isVideoSending}
							type="button"
							className="flex items-center justify-center w-full gap-2 px-5 py-2 text-white rounded-full disabled:opacity-70">
							{isVideoSending ? <IconLoader2 size={20} className="animate-spin" /> : <IconSend size={22} />}
							{isVideoSending ? "Sending..." : "Send"}
						</button>
					}
				</div>
			</div>
		</div>
	);
}

export default VideoPreview;