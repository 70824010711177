import React, { useRef } from 'react';
import { cn } from '../../../lib/utils';
import VideoPlayer from './VideoJsPlayer';
import videojs from 'video.js';

interface TMessageAttachment {
	url: string;
}

const VIDEO_URL = import.meta.env.VITE_VIDEO_URL;

function VideoMessage({ url }: TMessageAttachment) {
	const playerRef = useRef<ReturnType<typeof videojs> | null>(null);

	const videoJsOptions: IVideoOptionsProps = {
		autoplay: false,
		controls: true,
		responsive: true,
		playsinline: true,
		fluid: true,
		disablePictureInPicture: true,
		sources: [{
			src: `${url}${url?.includes(VIDEO_URL) ? '#t=0.001' : ''}`,
			type: 'video/mp4'
		}]
	};

	const handlePlayerReady = (player: ReturnType<typeof videojs>) => {
		playerRef.current = player;

		player.on('waiting', () => {
			// videojs.log('player is waiting');
		});

		player.on('dispose', () => {
			// videojs.log('player will dispose');
		});
	};

	return (
		<div className={cn('flex items-center max-w-full w-[50rem]')}>
			<VideoPlayer options={videoJsOptions} onReady={handlePlayerReady} />
		</div>
	);
}

export default React.memo(VideoMessage);
