/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable no-param-reassign */
import { create } from 'zustand';

type TChatState = {
  messages: TMessageData[];
  status: string;
  inputsDisabled: boolean;
  currentProjectToken: string;
  chatApiBaseUrl: string;
  refreshChatHistory: (() => Promise<void>) | null;
  refreshTimeout: () => void;
  refreshMessage: (message: string) => void;
  isLastMessageRerendered: string;
  noSpeechDetected: boolean;
  showVoiceMainInput: boolean;
};

type TChatAction = {
  setInitialMessages: (messages: TMessageData[]) => void;
  setCurrentProjectToken: (token: string) => void;
  addMessage: (message: TMessageData) => void;
  addMessageAndStartCreateAsistant: (message: TMessageData) => void;
  setSeen: () => void;
  updateLastMessage: (message: TMessageData) => void;
  setStatusOnLastMessage: (status: string) => void;
  setInputsDisabled: (value: boolean) => void;
  setReceived: () => void;
  clearMessages: () => void;
  addErrorMessage: (message: string) => void;
  setRefreshChatHistory: (refresh: (() => Promise<void>) | null) => void;
  refreshTimeout: () => void;
  refreshMessage: (message: string) => void;
  setIsLastMessageRerendered: (message: string) => void;
  setNoSpeechDetected: (speech: boolean) => void;
  setShowVoiceMainInput: (value: boolean) => void;
};

const newAsistantMessage = (): TMessageData => ({
  _id: Math.random().toString(36).substring(7),
  text: '',
  data: [],
  created_at: new Date().toISOString(),
  role: 'assistant',
  loading: true,
});

const newErrorMessage = (message: string): TMessageData => ({
  _id: Math.random().toString(36).substring(7),
  text: message,
  hasError: true,
  data: [],
  created_at: new Date().toISOString(),
  role: 'assistant',
  loading: false,
});

const useChatStore = create<TChatState & TChatAction>((set) => ({
  refreshChatHistory: null,
  messages: [] as TMessageData[],
  refreshTimeout: () => {
    set((state) => {
      const messages = [...state.messages];
      return { ...state, messages, inputsDisabled: false };
    });
  },

  noSpeechDetected: false,
  setNoSpeechDetected: (speech) => set({ noSpeechDetected: speech }),

  refreshMessage: (message: string) => {
    set((state) => {
      const messages = [...state.messages];
      const userMessages = messages.filter(message => message.role === 'user');
      const lastUserText = userMessages.length > 0 ? userMessages[userMessages.length - 1].text : '';

      localStorage.setItem('last_user_message', lastUserText);

      messages[messages.length - 1].loading = false;
      messages[messages.length - 1].text = message;
      messages[messages.length - 1].hasError = true;
      return { ...state, messages };
    });
  },
  setRefreshChatHistory: (refresh) => set((state) => ({ ...state, refreshChatHistory: refresh })),
  setInitialMessages: (messages: TMessageData[]) => set((state) => ({
    ...state,
    messages,
  })),
  addErrorMessage: (message: string) => set((state) => ({
    ...state,
    messages: [...state.messages, newErrorMessage(message)],
  })),
  clearMessages: () => set((state) => ({ ...state, messages: [] })),
  addMessage: (message: TMessageData) => set((state) => ({
    ...state,
    messages: [...state.messages, message],
    inputsDisabled: true,
  })),
  addMessageAndStartCreateAsistant: (message: TMessageData) => set((state) => ({
    ...state,
    messages: [...state.messages, message, newAsistantMessage()],
    inputsDisabled: true,
  })),
  updateLastMessage: (message: TMessageData) => set((state) => {
    const messages = [...state.messages];
    messages[messages.length - 1] = message;
    return { ...state, messages };
  }),
  setSeen: () => set((state) => ({
    ...state,
    messages: state.messages.map((message) => {
      if (message.role === 'user') {
        message.seen = true;
      }
      return message;
    }),
  })),
  setStatusOnLastMessage: (status: string) => {
    set((state) => {
      const messages = [...state.messages];
      messages[messages.length - 1].loading = false;
      messages[messages.length - 1].text = status;
      return { ...state, messages };
    });
  },
  setInputsDisabled: (value: boolean) => set((state) => ({ ...state, inputsDisabled: value })),
  setReceived: () => set((state) => ({
    ...state,
    messages: state.messages.map((message) => {
      if (message.role === 'user') {
        message.received = true;
      }
      return message;
    }),
  })),
  setCurrentProjectToken: (token: string) => set((state) => ({ ...state, currentProjectToken: token })),
  setIsLastMessageRerendered: (value: string) => set({ isLastMessageRerendered: value }),

  showVoiceMainInput: true,
  setShowVoiceMainInput: (value) => set({ showVoiceMainInput: value }),

  status: '',
  inputsDisabled: false,
  currentProjectToken: '',
  chatApiBaseUrl: '',
  isLastMessageRerendered: '',
}));

export default useChatStore;