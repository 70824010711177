import { useSearchParams } from 'react-router-dom';
import useTheme from 'chat-ui/src/hooks/useTheme';
import Image from 'chat-ui/src/components/Image/Image';
import Illustration from '@/assets/ConversationNotFound.svg';
import TelletLogo from '@/assets/tellet-logo-main.svg';
import EmpathyLogo from '@/assets/empathy-logo-black.svg';
import { ECompanyThemeName } from '@/enums/enums';

const ERROR_STATUSES = {
  PROJECT_IS_COMPLETED: '442',
  PROJECT_IS_DRAFT_OR_READY: '425',
  PROJECT_DO_NOT_EXIST: '404',
  MAXIMUM_COUNT_OF_CONVERSATION_EXCEEDED: '433',
  // METADATA_REQUIRED: '434',
  // INVALID_LANGUAGE: '435',
  CHAT_EXPIRED: '445',
};

const themeLogosList = {
  [ECompanyThemeName.DEFAULT]: <Image src={TelletLogo} alt="logo" height={50} width={50} className="absolute w-32 h-fit top-5 left-5" />,
  [ECompanyThemeName.EMPATHY]: <Image src={EmpathyLogo} alt="logo" height={120} width={120} className="absolute w-44 h-fit top-5 left-5" />,
};

function ChatRedirectedErrorPage() {
  const { themeName } = useTheme();
  const [searchParams] = useSearchParams();
  const error_status = searchParams.get('error_status');
  const isErrorStatusValid = Object.values(ERROR_STATUSES).includes(error_status as string);

  return (
    <div className="relative flex items-center justify-center min-h-screen px-4 mx-auto text-center">
      {/* logo */}
      {themeLogosList[themeName]}

      <div className="flex flex-col items-center justify-center gap-2 mx-auto text-center">
        <img
          src={Illustration}
          alt="illustration"
          className="min500:w-full w-[500px]"
        />
        <h1 className="text-5xl font-bold mt-14 text-dark min500:text-4xl">
          {error_status as string === ERROR_STATUSES.PROJECT_IS_COMPLETED
           && `${themeName === ECompanyThemeName.EMPATHY ? 'Project' : 'Tellet'} complete`}
          {error_status as string === ERROR_STATUSES.PROJECT_IS_DRAFT_OR_READY
           && `${themeName === ECompanyThemeName.EMPATHY ? 'Project' : 'Tellet'} not published`}
          {error_status as string === ERROR_STATUSES.PROJECT_DO_NOT_EXIST
           && `${themeName === ECompanyThemeName.EMPATHY ? 'Project' : 'Tellet'} does not exist`}
          {error_status as string === ERROR_STATUSES.MAXIMUM_COUNT_OF_CONVERSATION_EXCEEDED
           && 'Maximium amount of respondents'}
          {error_status as string === ERROR_STATUSES.CHAT_EXPIRED
           && `${themeName === ECompanyThemeName.EMPATHY ? 'Project' : 'Tellet'} is no longer available`}
          {!isErrorStatusValid
           && 'Something missing'}
        </h1>
        <p className="mt-2 text-lg font-medium text-light-dark">
          {error_status as string === ERROR_STATUSES.PROJECT_IS_COMPLETED
           && 'This study has been completed and is no longer accepting new responses.'}
          {error_status as string === ERROR_STATUSES.PROJECT_IS_DRAFT_OR_READY
           && 'Because study has not been published yet, it is not accepting responses.'}
          {error_status as string === ERROR_STATUSES.PROJECT_DO_NOT_EXIST
           && 'We\'re sorry but this project does not exist.'}
          {error_status as string === ERROR_STATUSES.MAXIMUM_COUNT_OF_CONVERSATION_EXCEEDED
           && 'This study has reached its maximum amount of responses and is no longer accepting new responses.'}
          {error_status as string === ERROR_STATUSES.CHAT_EXPIRED
           && `It seems that the ${themeName === ECompanyThemeName.EMPATHY ? 'Project' : 'Tellet'} you are looking for is no longer accessible.`}
          {!isErrorStatusValid && 'This page is unavailable or does not exist.'}
        </p>
      </div>
    </div>
  );
}

export default ChatRedirectedErrorPage;
