import useTheme from 'chat-ui/src/hooks/useTheme';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import useChatTitle from '@/hooks/useChatTitle';
import { ECompanyThemeName } from '@/enums/enums';

const faviconsList = {
  [ECompanyThemeName.DEFAULT]: '/favicon.ico',
  [ECompanyThemeName.EMPATHY]: '/faviconempathy.ico',
};

const themeNamesList = {
  [ECompanyThemeName.DEFAULT]: 'Tellet - Chat',
  [ECompanyThemeName.EMPATHY]: 'Empathy - Chat',
};

function ThemeWrapperChat() {
  const { changeTheme, themeName } = useTheme();
  const url = window.location.hostname;
  const parts = url.split('.');
  const nameFromUrl = parts[1];

  const tab_title = themeNamesList[themeName] || ECompanyThemeName.DEFAULT;
  const tab_favicon = faviconsList[themeName] || '/favicon.ico';

  function isValidThemeName(name: string): name is ECompanyThemeName {
    return Object.values(ECompanyThemeName).includes(name as ECompanyThemeName);
  }

  useEffect(() => {
    if (parts.length >= 3 && isValidThemeName(nameFromUrl)) {
      changeTheme(nameFromUrl);
    } else {
    // change theme for local testing here 👇
      changeTheme(ECompanyThemeName.DEFAULT);
    }
  }, [changeTheme, nameFromUrl, parts]);

  useChatTitle({ title: tab_title, favicon: tab_favicon });

  return <Outlet />;
}

export default ThemeWrapperChat;
